$(document).ready(function () {

	$('.drop').slideUp(0);

	// jQuery('.has-drop-down-a').hover(

	// 	function () {
	// 		jQuery(this).children('.drop').slideDown(100);
	// 	},

	// 	function () {
	// 		jQuery(this).children('.drop').delay(300).slideUp(100);
	// 	}
	// );

	jQuery('.has-drop-down-link').click(function() {
		$('.drop').not($(this).parent().parent().children('.drop')).slideUp(100);
		$(this).parent().parent().children('.drop').delay(100).slideToggle( "fast", function() {
			
		  });
	});

});