import Splide from '@splidejs/splide';
import LazyLoad from 'vanilla-lazyload';

try {
	window.Popper = require('popper.js').default;
	window.$ = window.jQuery = require('jquery');


	require('./filters.js');
	require('./menu.js');
	require('./scrolltotop.js');
	// require('./lazyload.js');
	require('./sl/simple-lightbox.js');

	var lightbox = new SimpleLightbox('.gallery a', {
		captionPosition: 'outside',
		navText: ['', ''],
		maxZoom: 1,
		doubleTapZoom: 1,
		scrollZoom: false,
		fadeSpeed: 0,
		animationSlide: false,
		closeText: '',
		heightRatio: 0.7,
		widthRatio: 0.8
	});

	new Splide('.splide', {
		type: "loop",
		autoplay: true,
		pagination: false,
		pauseOnHover: false,
		pauseOnFocus: false,
		interval: '5000',
		arrowPath: 'M5.048,4.016l-3.925,0l0,18.016l33.886,0l-15.223,15.223l2.746,2.745l16.345,-16.345l-0,-7.346l-16.309,-16.309l-2.749,2.748l15.14,15.14l-29.911,0l0,-13.872Z',
	}).mount();


} catch (e) { }


new LazyLoad({
	elements_selector: ".lazy",
});

// tarteaucitron.init({
//     "privacyUrl": "", /* Privacy policy url */

//     "hashtag": "#tarteaucitron", /* Open the panel with this hashtag */
//     "cookieName": "tarteaucitron", /* Cookie name */

//     "orientation": "top", /* Banner position (top - bottom - middle - popup) */

//     "groupServices": true, /* Group services by category */

//     "showAlertSmall": false, /* Show the small banner on bottom right */
//     "cookieslist": false, /* Show the cookie list */

//     "showIcon": true, /* Show cookie icon to manage cookies */
//     // "iconSrc": "", /* Optionnal: URL or base64 encoded image */
//     "iconPosition": "BottomLeft", /* Position of the icon between BottomRight, BottomLeft, TopRight and TopLeft */

//     "adblocker": false, /* Show a Warning if an adblocker is detected */

//     "DenyAllCta": true, /* Show the deny all button */
//     "AcceptAllCta": true, /* Show the accept all button when highPrivacy on */
//     "highPrivacy": true, /* HIGHLY RECOMMANDED Disable auto consent */

//     "handleBrowserDNTRequest": false, /* If Do Not Track == 1, disallow all */

//     "removeCredit": true, /* Remove credit link */
//     "moreInfoLink": true, /* Show more info link */
//     "useExternalCss": false, /* If false, the tarteaucitron.css file will be loaded */

//     //"cookieDomain": ".my-multisite-domaine.fr", /* Shared cookie for subdomain website */

//     "readmoreLink": "", /* Change the default readmore link pointing to tarteaucitron.io */

//     "mandatory": true /* Show a message about mandatory cookies */
// });

tarteaucitron.init({
	"privacyUrl": "", /* Privacy policy url */
	"bodyPosition": "bottom", /* or top to bring it as first element for accessibility */

	"hashtag": "#tarteaucitron", /* Open the panel with this hashtag */
	"cookieName": "tarteaucitron", /* Cookie name */

	"orientation": "top", /* Banner position (top - bottom) */
 
	"groupServices": false, /* Group services by category */
	"serviceDefaultState": "wait", /* Default state (true - wait - false) */
					 
	"showAlertSmall": false, /* Show the small banner on bottom right */
	"cookieslist": false, /* Show the cookie list */
					 
	"closePopup": false, /* Show a close X on the banner */

	"showIcon": true, /* Show cookie icon to manage cookies */
	//"iconSrc": "", /* Optionnal: URL or base64 encoded image */
	"iconPosition": "BottomLeft", /* BottomRight, BottomLeft, TopRight and TopLeft */

	"adblocker": false, /* Show a Warning if an adblocker is detected */
					 
	"DenyAllCta" : true, /* Show the deny all button */
	"AcceptAllCta" : true, /* Show the accept all button when highPrivacy on */
	"highPrivacy": true, /* HIGHLY RECOMMANDED Disable auto consent */
					 
	"handleBrowserDNTRequest": false, /* If Do Not Track == 1, disallow all */

	"removeCredit": false, /* Remove credit link */
	"moreInfoLink": true, /* Show more info link */

	"useExternalCss": false, /* If false, the tarteaucitron.css file will be loaded */
	"useExternalJs": false, /* If false, the tarteaucitron.js file will be loaded */

	//"cookieDomain": ".my-multisite-domaine.fr", /* Shared cookie for multisite */
					
	"readmoreLink": "", /* Change the default readmore link */

	"mandatory": true, /* Show a message about mandatory cookies */
	"mandatoryCta": true /* Show the disabled accept button when mandatory on */
  });

tarteaucitron.user.gtagUa = 'G-3EDYE7G398';
(tarteaucitron.job = tarteaucitron.job || []).push('gtag');