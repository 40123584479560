$(document).ready(function () {

    // alert('readyOnix') ;

    $(".filters__btn").on("click", function (e) {

        console.log($(this));

        var filtre_active = $(this).data('filter');
        $(".filters__btn").removeClass('filters__btn--active');
        $(this).addClass('filters__btn--active');

        if (filtre_active == 'all') {
            $(".types").removeClass('!hidden');
        } else {
            $(".types").addClass('!hidden');
            $(".type_" + filtre_active).removeClass('!hidden');
        }



    });
});